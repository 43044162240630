import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../Assets/images/new_logo.png";

const Header = () => {
  return (
    <Box
      sx={{
        background: "rgb(217 254 114 / 12%)",
        backdropFilter: "blur(10px)",
        py: "10px",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", 
            
          }}
        >
          <Typography component={"img"} src={logo} sx={{
            width:{sm:"230px",xs:"160px"}
          }} />
          <Box>
            <Button className="custom-button">
              Buy $INS
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
