import React from "react";
import { Box, Container, Typography } from "@mui/material";
import twitter from "../Assets/images/twitter.svg";
import doc from "../Assets/images/doc.svg";
import x from "../Assets/images/x.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        background: "rgb(217 254 114 / 12%)",
        backdropFilter: "blur(10px)",
        py: "10px",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: {md:"space-between",xs:"center"},
            alignItems: "center",
            flexWrap:"wrap",
            gap:"1rem"
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#fff",
            }}
          >
            © 2023 INSPAD. All rights reserved.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
          <a href="https://t.me/Insects_FUTURES" target="_blank">
            <Typography component={"img"} src={twitter} />
          </a>
          <a href="https://x.com/insectsvip?s=21" target="_blank">
          <Typography component={"img"} src={x} />
          </a>
          <a href="https://docs.insects.vip" target="_blank">
          <Typography component={"img"} src={doc} />
          </a>
            
        
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
