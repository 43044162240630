import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";


function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>   

        
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
