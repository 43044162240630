import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import home_bg from "../../Assets/images/home_bg.png";
import semicircle from "../../Assets/images/semicircle.png";
import right_circle from "../../Assets/images/right_circle.png";
import left_circle from "../../Assets/images/left_circle.png";
import axios from "axios";
import { API_URL, BASE_URL, BSC_URL, ETH_URL, TON_URL, TRON_URL } from "../../Config/Config";

const data = [
  {
    ton: "952 TON",
    network: "TON Network",
  },
  {
    ton: "215 TRON",
    network: "TRON Network",
  },
  {
    ton: "44 ETH",
    network: "ETH Network",
  },
  {
    ton: "744 BNB",
    network: "BNB Network",
  },
  {
    ton: "2,744 ETH",
    network: "BASE Network",
  },
];



const Home = () => {
  const [revenue,setRevenue]=useState([])
  const [totalSum, setTotalSum] = useState(0);
  const getRevenue = async() => {

    try {
        const res = await axios.get(`${API_URL}/get/all/revenue`);
        if (res.status===200) {
          setRevenue(res.data.data)
        }
      } catch (err) {
        console.log(err);
      }
  
  }
  
  useEffect(()=>{
    getRevenue()
  },[])
  useEffect(() => {
    // Calculate the total summation of totalRevenue * currencyValue
    const sum = revenue.reduce(
      (acc, item) => acc + (item.totalRevenue * item.currencyValue || 0),
      0
    );
    setTotalSum(sum);
  }, [revenue]);

  
  return (
    <Box
   sx={{
    backgroundImage: `url(${home_bg})`,
    backgroundPosition: "100% 100%",
    backgroundRepeat: "repeat-y", // Repeat vertically
    backgroundSize: "cover",
    height: "100vh",
    overflow: "auto",
    animation: "marquee 4s linear infinite",
    "@keyframes marquee": {
      "0%": {
        backgroundPositionY: "0",
      },
      "100%": {
        backgroundPositionY: "-100vh", // Adjust based on desired scroll amount
      },
    },
  }}
    >
      <Header />
      <Box
        position={"relative"}
        sx={{
          mt: { md: "-5rem", xs: "10rem" },
        }}
      >
        <Typography
          component={"img"}
          src={semicircle}
          sx={{
            display: { md: "block", xs: "none" },
            position: "absolute",
            top: "28%",
            left: "0%",
            transform: { md: "translate(0,-28%)" },
          }}
        />
        <Container
          sx={{
            position: "relative",
          }}
        >
          <Typography
            component={"img"}
            src={right_circle}
            width={"100%"}
            sx={{
              display: { md: "block", xs: "none" },
            }}
          />
          <Box
            sx={{
              width: "100%",
              position: { md: "absolute", xs: "static" },
              top: "35%",
              left: "0%",
              transform: "translate(0,-35%)",
            }}
          >
            <Container>
              <Grid container spacing={2} my={"1rem"}>
                <Grid
                  item
                  md={8}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    "& p": {
                      lineHeight: "normal",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { md: "40px", xs: "20px" },
                      fontFamily: "Aldrich",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Step into the future with
                  </Typography>
                  <Typography
                    fontFamily={"MabryPro"}
                    color={"#D9FE72"}
                    fontWeight={"600"}
                    sx={{
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                      fontSize: { md: "178px", xs: "50px" },
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    INSPAD's
                  </Typography>
                  <Typography
                    sx={{
                      mt: { md: "-2rem" },
                      color: "#fff",
                      fontSize: { md: "40px", xs: "20px" },
                      fontFamily: "Aldrich",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    expertly designed utilities.
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "flex-end", xs: "center" },
                  }}
                >
                  <Box
                    sx={{
                      m: { md: "2rem", xs: "0" },
                      display: "grid",
                      gap: "1rem",
                      "& button": {
                        width: "150px !important",
                      },
                    }}
                  >
                    <Button component="a" href="/" className="custom-button">Launch Now</Button>
                    {/* <Button className="custom-button">Stake $INS</Button> */}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          mt: { md: "-25rem" },
        }}
      >
        <Container
          sx={{
            position: "relative",
          }}
        >
          <Typography
            component={"img"}
            src={left_circle}
            width={"100%"}
            sx={{
              display: { md: "block", xs: "none" },
            }}
          />
          <Box
            sx={{
              width: "100%",
              position: { md: "absolute", xs: "static" },
              top: "50%",
              left: "0%",
              transform: { md: "translate(0,-50%)" },
            }}
          >
            <Container>
              <Grid
                container
                spacing={2}
                sx={{
                  my: { md: "4rem", xs: "2rem" },
                }}
              >
                <Grid item md={7} sm={12} xs={12}>
                  <Box
                    sx={{
                      p: { md: "2.5rem" },
                      display: "flex",
                      height: "100%",
                      gap: "1rem",
                      flexDirection: "column",
                      "& p": {
                        lineHeight: "normal",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #D9FE72",
                        p: "10px 20px",
                        borderRadius: "30px",
                        alignSelf: { md: "flex-start", xs: "center" },
                      }}
                    >
                      <Typography
                        fontFamily={"Aldrich"}
                        fontSize={"16px"}
                        color={"#fff"}
                        sx={{
                          textAlign: { md: "start", xs: "center" },
                        }}
                      >
                        <span style={{ color: "#D9FE72" }}>
                          Total Revenue on Launchpad
                        </span>{" "}
                       ${parseInt(totalSum).toFixed(2)}
                      </Typography>
                    </Box>
                    <Typography
                      fontFamily={"Aldrich"}
                      color={"#D9FE72"}
                      sx={{
                        fontSize: { md: "100px", xs: "40px" },
                        textAlign: { md: "start", xs: "center" },
                        alignSelf: { md: "flex-start", xs: "center" },
                      }}
                    >
                      Revenue
                      <br />
                      Share
                    </Typography>

                    <Typography
                      fontFamily={"MabryPro"}
                      fontSize={"16px"}
                      color={"#fff"}
                      mt={"-1rem"}
                      alignSelf={"flex-start"}
                      sx={{
                        textAlign: { md: "start", xs: "center" },
                      }}
                    >
                      Grow Your Earnings with Ease and Enjoy the Ride!
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    {revenue?.map((data, i) => (
                      <Box
                        key={i}
                        sx={{
                          background: {
                            md: "linear-gradient(to right, #1f1f1f40 0%, #d9fe72cc 100%)",
                            xs: "linear-gradient(to right, #1f1f1f 0%, #d9fe72cc 100%)",
                          },
                          p: "10px 30px",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          fontFamily={"MabryPro"}
                          fontSize={"16px"}
                          color={"rgb(255 255 255 / 52%)"}
                        >
                          <span style={{ color: "#fff", fontWeight: "600" }}>
                            {parseFloat(data?.totalRevenue).toFixed(2)}{" "}
                            {
                              data?.chainId==="97" || data?.chainId==="56" ?"BNB":data?.chainId==="1"?"ETH":data?.chainId==="TON"?"TON":
                              data?.chainId==="TRON"?"TRX":"ETH"
                            } {" "}
                            
                          </span>{" "}
                          {
                              data?.chainId==="97" || data?.chainId === "56"  ?"BSC":data?.chainId==="1"?"Ethereum":data?.chainId==="TON"?"TON":
                              data?.chainId==="TRON"?"TRON":"BASE"
                            } {" "}
                            Network
                        </Typography>

                        <Button className="custom-button" component="a" href={
                          (data?.chainId==="97" || data?.chainId==="56" ?BSC_URL:data?.chainId==="1"?ETH_URL:data?.chainId==="TON"?TON_URL:
                              data?.chainId==="TRON"?TRON_URL:BASE_URL) + data?.Wallet
                         } target="_blank">See Wallet</Button>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default Home;
